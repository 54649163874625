* {
  font-family:Inter,Helvetica,sans-serif
}

section {
  text-align:center
}

article {
  display:inline-block;
  max-width:768px;
  text-align:left;
  width:100%
}

article.about {
  max-width:370px;
  width:100%
}

h1,h2,h3,h4,h5,h6 {
  font-size:medium
}

.date {
  color:#a9a9a9
}

p {
  color:#202122
}

a {
  color:#0645ad;
  text-decoration:none
}

a:hover {
  text-decoration:underline
}

ul {
  list-style-type:none;
  padding:0;
}
