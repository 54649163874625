* {
  font-family: 'Inter', sans-serif;
}

.container {
  display: block;
  text-align: center;
}

.content {
  display: inline-block;
  max-width: 760px;
  text-align: left;
  width: 100%;
}